import { IToken } from "root/client/src/stateMachines/tokens/IToken";

export class UnparsableToken implements IToken {
  type: "UnparsableToken" = "UnparsableToken";

  constructor(public readonly auth: string) {
  }

  static isUnparsable(token: IToken): token is UnparsableToken {
    return token.type === "UnparsableToken";
  }

  equals(other: any): boolean {
    if (other.type !== "UnparsableToken") {
      return false;
    }
    return this.auth === other.auth;
  }
}
