import { IAuthFlow } from "root/client/src/stateMachines/IAuthFlow";
import { zoomConfig } from "root/client/src/stateMachines/zoomConfigLoader";
import { errorMsg } from "src/libs/notifications";

const MAX_REDIRECTS = 100;

const PUBLIC_ROUTES = ["/zoom/browserLoginNotice"];

function findHomePage(isZoom: boolean) {
  if (isZoom) {
    return "/zoom";
  }
  return "/";
}

function isSignupRoute(route: string) {
  return route.startsWith("/signup") || route.startsWith("/join_account/") || route.startsWith("/zoom/signup");
}

function isZoomRoute(route: string) {
  return route.startsWith("/zoom");
}

function isLoginRoute(route: string) {
  return route.startsWith("/login");
}

// The signup and onboarding flow is designed/documented here: https://www.notion.so/instantmarketing/Signup-and-Onboarding-Flow-851784590e9b4e3d8b2a9b4859ab3aa3?pvs=4
export function shouldRedirect(authFlow: IAuthFlow, route: string) {
  const isZoom = zoomConfig.isZoomApp;
  const currentRoute = window.location.pathname;
  const isPublicRoute = PUBLIC_ROUTES.some((r) => r === currentRoute);

  // const isLoadingAuth = !authFlow.isLoginStateKnown;
  // const isDefinitelyNotLoggedIn = authFlow.isLoginStateKnown && !authFlow.isLoggedIn;
  const isDefinitelyLoggedIn = authFlow.isLoginStateKnown && authFlow.isLoggedIn;
  const isSignup = authFlow.isLoginStateKnown && authFlow.isGuest;
  const home = findHomePage(isZoom);

  if (isPublicRoute) {
    console.log("shouldRedirect: isPublicRoute");
    return null;
  }

  // if (isZoomRoute(route) && !isZoom) {
  //   console.log(`Zoom route detected. Redirecting from ${route} to ${home}`);
  //   return home;
  // }

  if (isLoginRoute(route) && isDefinitelyLoggedIn) {
    console.log(`Login route detected. Redirecting from ${route} to ${home}`);
    return home;
  }

  if (!isSignupRoute(route) && isSignup && !isZoom) {
    console.log(`Signup route detected. Redirecting from ${route} to /signup. isGuest: ${authFlow.isGuest}, isKnown: ${authFlow.isLoginStateKnown}`);
    return "/signup";
  }

  if (isSignupRoute(route) && authFlow.isLoginStateKnown && !isSignup) {
    console.log(`Not a signup route. Redirecting from ${route} to ${home}`);
    return home;
  }

  if (!isZoomRoute(route) && isZoom) {
    console.log(`Not a zoom route. Redirecting from ${route} to /zoom`);
    return "/zoom";
  }

  console.log("shouldRedirect: no redirect needed");
  return null;
}

export function redirectIfNeeded(kind: "signup" | "login", defaultPath?: string): void {
  const currentRoute = window.location.pathname;

  const totalRedirects = parseInt(sessionStorage.getItem("totalRedirects") || "0", 10);
  sessionStorage.setItem("totalRedirects", (totalRedirects + 1).toString());

  const isPublicRoute = PUBLIC_ROUTES.some((r) => r === currentRoute);

  if (totalRedirects > MAX_REDIRECTS) {
    errorMsg("Too many redirects", "Redirected too many times, please contact support");
    throw new Error(`Too many redirects... Redirected more than ${MAX_REDIRECTS} times`);
  }

  if (isPublicRoute) {
    return;
  }

  switch (kind) {
    case "signup":
      if (isSignupRoute(currentRoute)) {
        return;
      }
      console.debug(`Redirecting to signup from ${currentRoute}`);
      window.location.href = defaultPath || "/signup";
      break;
    case "login":
      if (isLoginRoute(currentRoute)) {
        return;
      }

      // eslint-disable-next-line no-case-declarations
      const lastRedirectedToLogin = parseInt(sessionStorage.getItem("lastRedirectedToLogin") || "0", 10);

      if (Date.now() - lastRedirectedToLogin < 100) {
        console.debug(`Not redirecting to login from ${currentRoute} because they were already redirected within the last 100 milliseconds`);
        return;
      }
      sessionStorage.setItem("lastRedirectedToLogin", Date.now().toString());
      console.debug(`Redirecting to login from ${currentRoute}`); window.location.href = defaultPath || "/login";
      break;
    default:
      return;
  }

}
