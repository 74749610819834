import { FirebaseApp, initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebase = {
  apiKey: import.meta.env.DT_FB_API_KEY,
  authDomain: import.meta.env.DT_FB_AUTH_DOMAIN,
  projectId: import.meta.env.DT_FB_PROJECT_ID,
  storageBucket: import.meta.env.DT_FB_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.DT_FB_MESSAGING_SENDER_ID,
  appId: import.meta.env.DT_FB_APP_ID,
  measurementId: import.meta.env.DT_FB_MEASUREMENT_ID,
  };
export const functionUrlApi = import.meta.env.DT_function_api;
export const functionUrlZoom = import.meta.env.DT_function_zoom;
export const functionUrlPipeline = import.meta.env.DT_function_pipeline;
export const functionUrlDevtools = import.meta.env.DT_function_devtools;
export const functionUrlClientlogs = import.meta.env.DT_function_clientlogs;
export const functionUrlCredits = import.meta.env.DT_function_credits;
export const functionUrlTeams = import.meta.env.DT_function_teams;

export const functionUrlAuth = import.meta.env.DT_function_auth;

export const functionUrlAuthSubdomain = import.meta.env.DT_function_auth_subdomain || functionUrlAuth;

export const clientUrl = import.meta.env.DT_BASE_URL || "http://127.0.0.1:5173";
export const zoomClient = "6RtRAF2yS2S3owdq5r5zEw";
export const zoomRedirect = "https://jaf-fbfunc.eu.ngrok.io/demotime-25f10/europe-west1/zoom/connect/";
    // "efifdgagecmfilebahnalhhblpidemje"; // sam home PC;
    // browserExtensionId: "ekjcknmljcklnglolhlgnbojljddcmdo", // sam laptop PC
export const playerDomain = "https://demotime-video.web.app";

export const fbApp:FirebaseApp = initializeApp(firebase);
export const auth = getAuth(fbApp);
export const db = getFirestore(fbApp);
export const environment = import.meta.env.DT_environment;
