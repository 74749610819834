import { observer } from "mobx-react";
import React, { FunctionComponent } from "react";
import { TbDoorExit, TbSpy } from "react-icons/tb";
import { AsyncButton } from "root/client/src/components/settings/asyncButton";
import { useIsImitating, useIsLoggedIn, useUserToken } from "root/client/src/hooks/useDemoTimeAuth";
import { useAuthFlow } from "root/client/src/stateMachines/mobxContext";

import { ActionIcon, Affix, createStyles, Tooltip } from "@mantine/core";

const useStyles = createStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    gap: theme.spacing.md,
    background: theme.colors["demotime-violet"][0],
    padding: theme.spacing.md,
    borderRadius: theme.radius.lg,
    marginBottom: theme.spacing.md,
    marginRight: theme.spacing.md,
    "&:hover": {
      opacity: 0.3
    }
  },
  left: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    width: 50
  },
  centre: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    flexGrow: 1,
    fontSize: theme.fontSizes.xs,
  },
  right: {
    alignSelf: "flex-end",
  },
  title: {
    fontWeight: 700,
    fontSize: theme.fontSizes.sm,
    color: theme.colors["demotime-cyan"][7],
  },
  subtitle: {
    fontWeight: 400,
    fontSize: theme.fontSizes.xs,
    color: theme.colors.gray[6],
  },
  displayName: {
    fontWeight: 700,
    fontSize: theme.fontSizes.sm,
    color: theme.colors["demotime-cyan"][7],
    alignSelf: "center",
    paddingTop: theme.spacing.xs,

  }
}));

export const ImitationReminder = observer(() => {
  const isLoggedIn = useIsLoggedIn();
  const currentUser = useUserToken();
  const isImitating = useIsImitating();
  const authFlow = useAuthFlow();
  const { classes } = useStyles();

  const stopImitating = () => authFlow.superAdminStopImitatingUser();
  if (isLoggedIn && currentUser && isImitating) {
    return <Affix>
      <div className={classes.root}>
        <div className={classes.left}>
          <TbSpy size={40} />
        </div>
        <div className={classes.centre}>
          <div className={classes.title}>Imitating User</div>
          <div className={classes.subtitle}>All requests and data will be made as user</div>
          <div className={classes.displayName}>{currentUser.displayName}</div>
        </div>
        <div className={classes.right}>
          <Tooltip label="Go back to being myself">
            <AsyncButton onClick={stopImitating} leftIcon={<TbDoorExit size={15} />} color="gray" size="xs">
            Cancel
            </AsyncButton>
          </Tooltip>
        </div>
      </div>
           </Affix>;
  }
  return null;
}) as FunctionComponent;

ImitationReminder.displayName = "ImitationReminder";
