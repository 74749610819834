// The signup and onboarding flow is designed/documented here: https://www.notion.so/instantmarketing/Signup-and-Onboarding-Flow-851784590e9b4e3d8b2a9b4859ab3aa3?pvs=4

import "./App.scss";
import "./tracing";

import { domAnimation, LazyMotion } from "framer-motion";
// import { H } from "highlight.run";
import React, { useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { MobxProvider } from "root/client/src/stateMachines/mobxContext";
import { TbSearch } from "react-icons/tb";

import { ColorScheme, ColorSchemeProvider, MantineProvider } from "@mantine/core";
import { ModalsProvider } from "@mantine/modals";
import { Notifications } from "@mantine/notifications";
import { SpotlightAction, SpotlightProvider } from "@mantine/spotlight";
import { createSyncStoragePersister } from "@tanstack/query-sync-storage-persister";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { persistQueryClient } from "@tanstack/react-query-persist-client";
import { PostHogProvider } from "posthog-js/react";

import { AppRoutes } from "./AppRoutes";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 1000 * 60 * 60 * 24, // 24 hours
    },
  },
});

// Wrapper component for conditional PostHog usage
function ConditionalPostHogProvider({ children } : { children: React.ReactNode }) {
  const isDevelopment = window.location.hostname.includes("localdev.example.com") || window.location.hostname.includes("localhost");

  if (isDevelopment) {
    // In development environment, return children without PostHog
    return <>{children}</>;
  } else {
    // In other environments, use PostHogProvider
    return (
      <PostHogProvider apiKey="phc_Y9XBK3GIrmf8iF3QTwOIkLGvAttDs82GdPe5GhCtZ59" options={{ api_host: "https://ph.justadd.workers.dev" }}>
        {children}
      </PostHogProvider>
    );
  }
}

const localStoragePersister = createSyncStoragePersister({ storage: window.localStorage });

persistQueryClient({
  queryClient,
  persister: localStoragePersister,
});

export function App() {
  const [colorScheme, setColorScheme] = useState<ColorScheme>("light");
  const toggleColorScheme = (value?: ColorScheme) => setColorScheme(value || (colorScheme === "dark" ? "light" : "dark"));
  const actions: SpotlightAction[] = [];

  // // init highlight.io
  // if (!document.location.href.includes("localdev") && !(document.location.href.includes("localhost")) && process.env.NODE_ENV !== "development" && process.env.NODE_ENV !== "dev") {
  //   H.init("jgop85dl", {
  //     tracingOrigins: true,
  //     networkRecording: {
  //       enabled: true,
  //       recordHeadersAndBody: true,
  //       urlBlocklist: [
  //         // insert full or partial urls that you don't want to record here
  //         // Out of the box, Highlight will not record these URLs (they can be safely removed):
  //         "https://www.googleapis.com/identitytoolkit",
  //         "https://securetoken.googleapis.com",
  //       ],
  //     },
  //   });
  // }

  const result = (
    <ColorSchemeProvider colorScheme={colorScheme} toggleColorScheme={toggleColorScheme}>
      <MantineProvider
        withGlobalStyles
        withNormalizeCSS
        theme={{
          /** Put your mantine theme override here */
          colorScheme,
          other: {
            maxWidth: 1200
          },
          colors: {
            "demotime-violet": [
              "#F4EEF7",
              "#E1CCEC",
              "#D2A9E7",
              "#C782EA",
              "#B570D8",
              "#A362C4",
              "#9358B1",
              "#83559A",
              "#745285",
              "#674F73"
            ],
            "demotime-cyan": [
              "#F1F7FA",
              "#CEE3F1",
              "#A7D4EF",
              "#7CC7F5",
              "#69B5E3",
              "#5BA3CF",
              "#5293BB",
              "#4F83A4",
              "#4E748C",
              "#4C6778"
            ]
          }
        }}
      >
        <ModalsProvider labels={{
          confirm: "Confirm",
          cancel: "Cancel"
        }}
        >

          <QueryClientProvider
            client={queryClient}
          >

            <SpotlightProvider
              actions={actions}
              searchIcon={<TbSearch size={18} />}
              searchPlaceholder="Search..."
              shortcut=""
              nothingFoundMessage="Nothing found..."
            >
              <Notifications position="top-right" />
              <ConditionalPostHogProvider>
                <LazyMotion features={domAnimation}>
                  <Router>
                    <MobxProvider>
                      <AppRoutes />

                    </MobxProvider>

                  </Router>
                </LazyMotion>
              </ConditionalPostHogProvider>
            </SpotlightProvider>
          </QueryClientProvider>

        </ModalsProvider>
      </MantineProvider>
    </ColorSchemeProvider>
  );
  return result;
}
