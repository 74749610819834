export type RouteContext = "main" | "auth" | "popup" | "zoom" ;

export interface IRouteOptions {
  /**
   * The displayname to show in the react component tree - used for debugging only
   */
  displayName?: string;
  /**
   * Which layout to show. Defaults to "full"
   */
  layout?: "full" | "simple";
  /**
   * If true, the route will show as "loading" until API access is available.
   */
  waitForLogin?: boolean;
}

export type ResolvedRouteOptions = Required<IRouteOptions>;

export function routeOptionsWithDefaults(options: IRouteOptions): ResolvedRouteOptions {
  return {
    layout: "full",
    waitForLogin: false,
    displayName: "WrappedRoute",
    ...options,
  };
}
