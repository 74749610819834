import { functionUrlAuthSubdomain } from "root/client/src/config";

const PKCE_RETRIES = 3;
const PKCE_TIMEOUT = 5000;

async function loadClientPKCE(): Promise<{codeChallenge: string, state: string}> {
  const result = await fetch(`${functionUrlAuthSubdomain}/clientPKCE`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    credentials: "include",
    body: JSON.stringify({})
  });

  if (result.ok) {
    const resultJson = await result.json();
    const codeChallenge = resultJson.codeChallenge;
    const state = resultJson.state;

    return { codeChallenge, state };
  }
  throw new Error("Failed to load client PKCE");
}

export async function loadClientPKCETimeoutAndRetry():Promise<{codeChallenge: string, state: string}> {
  let retries = 0;

  return new Promise((resolve, reject) => {
    function executePromise() {
      loadClientPKCE()
        .then((result) => {
          resolve(result);
        })
        .catch((error) => {
          retries++;
          if (retries < PKCE_RETRIES) {
            console.log(`PCKE Promise failed. Retrying... (${retries}/${PKCE_RETRIES})`);
            setTimeout(executePromise, PKCE_TIMEOUT);
          } else {
            reject(error);
          }
        });
    }
    executePromise();
  });
}
