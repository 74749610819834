// The signup and onboarding flow is designed/documented here: https://www.notion.so/instantmarketing/Signup-and-Onboarding-Flow-851784590e9b4e3d8b2a9b4859ab3aa3?pvs=4

import { observer } from "mobx-react";
import * as React from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import {
    IRouteOptions, ResolvedRouteOptions, routeOptionsWithDefaults
} from "root/client/src/IRouteOptions";
import { useAuthFlow } from "root/client/src/stateMachines/mobxContext";
import { shouldRedirect } from "root/client/src/stateMachines/shouldRedirect";
import { SwitchLayout } from "root/client/src/SwitchLayout";
import Loading from "src/components/loading";
import { useEffectOnce } from "src/hooks/useEffectOnce";

import loadable, { LoadableComponent } from "@loadable/component";

const CLIENT_VERSION = "1.0";

type DefaultComponentModule = { default: React.ComponentType };
type LoadFn = (props: React.ComponentProps<React.ComponentType>) => Promise<React.ComponentType | DefaultComponentModule>;

/**
 * Will wrap the given route information *before* the async load - this should be used to do things like
 * redirect to a different page if the user is not logged in and identify this as a popup.
 * @param LoadedComponent
 */
function wrapBeforeLoad(LoadedComponent: LoadableComponent<{}>, options: ResolvedRouteOptions): React.ComponentType<{}> {
  return function () {
    const currentRoute = useLocation();
    // is this the popup home
    const isPopupHome = currentRoute.pathname === "/popup";
    // if sessionStorage.popup isn't "true" - set it to "true"
    if (isPopupHome) {
      sessionStorage.setItem("popup", "true");
    }
    const auth = useAuthFlow();
    const redirectToPage = shouldRedirect(auth, currentRoute.pathname);

    if (redirectToPage && redirectToPage !== null) {
      return <Navigate to={redirectToPage} />;
    }

    if (options.waitForLogin) {
      if (!auth.canMakeFirestoreRequests) {

        return <Loading />;
      }
    }
    return <LoadedComponent />;
  };
}

/**
 * Will wrap the component with things that should be done *after* the component is loaded.
 *
 * This should be used for things like applying the appropriate layout.
 * @param loadFn The loader for the component itself.
 * @param layout The layout to apply
 */
async function wrapAfterLoad(loadFn: LoadFn, options: ResolvedRouteOptions): Promise<React.ComponentType<{}>> {
  const LoadedComponentOrModule = await loadFn({});
  const hasDefault = Object.prototype.hasOwnProperty.call(LoadedComponentOrModule, "default");
  const LoadedComponent = hasDefault ? (LoadedComponentOrModule as DefaultComponentModule).default : LoadedComponentOrModule as React.ComponentType;

  return function () {
    return <SwitchLayout layout={options.layout}><LoadedComponent /></SwitchLayout>;
  };
}

/**
 * Wraps the loadable component in a SwitchLayout - this basically means we don't start showing the layout until that page is actually loaded (important!)
 * @param loadFn - the loadable function to wrap - like <code>() => import("src/components/meetings")</code>
 */
function routeHelper(loadFn: LoadFn, options: IRouteOptions = {}): LoadableComponent<{}> {

  const fullOptions = routeOptionsWithDefaults(options);
  const LoadableInner = loadable(() => wrapAfterLoad(loadFn, fullOptions), {
    fallback: <Loading delay={500} />, // Don't show the loading screen until 500ms has passed
  });

  const result = observer(wrapBeforeLoad(LoadableInner, fullOptions)) as LoadableComponent<{}>;

  result.preload = () => LoadableInner.preload();
  result.load = () => LoadableInner.load();
  result.displayName = fullOptions.displayName;

  return result;
}

const Meetings = routeHelper(() => import("src/components/meetings"), { waitForLogin: true });
const Playground = routeHelper(() => import("src/components/playground/playground"), { waitForLogin: true });
const Internal = routeHelper(() => import("src/components/internal/internal"), { waitForLogin: true });
const PlaygroundMeeting = routeHelper(() => import("src/components/playground/playgroundMeeting"), { waitForLogin: true });
const Scene = routeHelper(() => import("src/components/scenes/scene"), { waitForLogin: true });
const Scenes = routeHelper(() => import("root/client/src/components/scenes/scenes"), { waitForLogin: true });
const SignupFlow = routeHelper(() => import("src/components/signUp"), {
  layout: "simple",
  waitForLogin: true
});
const Settings = routeHelper(() => import("src/components/settings/settings"), { waitForLogin: true });
const Login = routeHelper(() => import("src/components/login"), { layout: "simple" });

const NotFound = routeHelper(() => import("src/components/notFound"), { layout: "simple" });

const StripeSuccess = routeHelper(() => import("root/client/src/components/settings/stripeSuccess").then((m) => m.StripeSuccess), { waitForLogin: true });

const StripeCancelled = routeHelper(() => import("root/client/src/components/settings/stripeCancelled").then((m) => m.StripeCancelled), { waitForLogin: true });

const ZoomBrowserLoginNotice = routeHelper(() => import("root/client/src/components/zoom/zoomBrowserLoginNotice").then((m) => m.ZoomBrowserLoginNotice), {
  layout: "simple",
  waitForLogin: false
});

const ZoomConnected = routeHelper(() => import("root/client/src/components/settings/integrations/zoomConnected").then((m) => m.ZoomConnected), { waitForLogin: true });

const ZoomError = routeHelper(() => import("root/client/src/components/settings/integrations/zoomError").then((m) => m.ZoomError), { waitForLogin: true });
const MobileUnsupportedNotice = routeHelper(() => import("root/client/src/components/mobileUnsupportedNotice").then((m) => m.MobileUnsupportedNotice),);
const Superadmin = routeHelper(() => import("src/components/superadmin/superAdmin").then((m) => m.SuperAdmin), { waitForLogin: true });
const HomeRoute = routeHelper(() => import("src/HomeRoute").then((m) => m.HomeRoute), { waitForLogin: true });
const AiScenesRoute = routeHelper(() => import("src/components/ai/aiScenes").then((m) => m.AIScenes), { waitForLogin: true });
const TeamsIntegrationDetail = routeHelper(() => import("src/components/settings/integrations/teamsIntegrationDetail").then((m) => m.TeamsIntegrationDetail), { waitForLogin: true });
const TeamsAuthorizedSplash = routeHelper(() => import("src/components/splash/TeamsAuthorizedSplash").then((m) => m.TeamsAuthorizedSplash), { layout: "simple", waitForLogin: false });
const TeamsAuthorizedStep2Splash = routeHelper(() => import("src/components/splash/TeamsAuthorizedStep2Splash").then((m) => m.TeamsAuthorizedStep2Splash), { layout: "simple", waitForLogin: false });
const TeamsStartInstallSplash = routeHelper(() => import("src/components/splash/TeamsStartInstallSplash").then((m) => m.TeamsStartInstallSplash), { layout: "simple", waitForLogin: false });
const LoginForBxSplash = routeHelper(() => import("src/components/splash/LoginForBxSplash").then((m) => m.LoginForBxSplash), { layout: "simple", waitForLogin: false });
const Temp = routeHelper(() => import("src/components/temp").then((m) => m.Temp), { layout: "simple", waitForLogin: false });
const PlaygroundExternalMeeting = routeHelper(() => import("src/components/playground/external/playgroundExternalMeeting").then((m) => m.PlayGroundExternalMeeting), { layout: "simple", waitForLogin: false });
const ImportMeeting = routeHelper(() => import("src/components/importMeeting").then((m) => m.ImportMeeting), { waitForLogin: true });
const Automations = routeHelper(() => import("src/components/automations").then((m) => m.Automations), { waitForLogin: true });
const ZoomOverview = routeHelper(() => import("src/components/zoom/ZoomOverview").then((m) => m.ZoomOverview), { layout: "simple", waitForLogin: true });

export const UnguardedRouteMap = [
  {
    path: "/login",
    element: <Login />
  },
  {
    path: "/signup",
    element: <SignupFlow />
  },
  {
    path: "/join_account/:accountId",
    element: <SignupFlow />
  },
  {
    path: "/zoom/browserLoginNotice",
    element: <ZoomBrowserLoginNotice />
  },
  { path: "/login_for_bx", element: <LoginForBxSplash /> }
];

export const RouteMap = [
  {
    path: "/",
    element: <HomeRoute />
  },
  {
    path: "/meetings",
    element: <Meetings />
  },
  {
    path: "/scenes",
    element: <Scenes />
  },
  {
    path: "/content",
    element: <Scenes />
  },
  {
    path: "/scenes/:id",
    element: <Scene />
  },
  {
    path: "/playground",
    element: <Playground />
  },
  {
    path: "/playground/:id",
    element: <PlaygroundMeeting />
  },
  {
    path: "/internal",
    element: <Internal />
  },
  {
    path: "/settings/*",
    element: <Settings />
  },
  {
    path: "/settings/stripe_success",
    element: <StripeSuccess />
  },
  {
    path: "/settings/stripe_cancelled",
    element: <StripeCancelled />
  },
  {
    path: "/zoom/signup/:env?",
    element: <SignupFlow />
  },
  {
    path: "/settings/zoom_connected",
    element: <ZoomConnected />
  },
  {
    path: "/settings/zoom_error",
    element: <ZoomError />
  },
  {
    path: "/mobile_unsupported_notice",
    element: <MobileUnsupportedNotice />
  },
  { path: "/import_meeting", element: <ImportMeeting /> },
  { path: "/project_onboarding", element: <ZoomOverview /> },
  {
    path: "/superadmin/*?",
    element: <Superadmin />
  },
  { path: "/ai_scenes", element: <AiScenesRoute /> },
  {
    path: "/settings/integrations/teamsIntegrationDetail",
    element: <TeamsIntegrationDetail />
  },
  { path: "/teams_authorized", element: <TeamsAuthorizedSplash /> },
  { path: "/teams_authorized_step2", element: <TeamsAuthorizedStep2Splash /> },
  { path: "/teams_start_install", element: <TeamsStartInstallSplash /> },
  { path: "/playground_external/:platform/:meetingId", element: <PlaygroundExternalMeeting /> },

  { path: "/temp", element: <Temp /> },
  { path: "/automations", element: <Automations /> },
  { path: "/zoom", element: <ZoomOverview /> }
];

const TO_PREFETCH = [
  Meetings,
  Scenes,
  Settings,
  Scene,
  Automations
];

const NEVER_PREFETCH_ON = [
  "/recap"
];

const useBackgroundPrefetch = () => {
  const location = useLocation();
  useEffectOnce(() => {

    if (NEVER_PREFETCH_ON.some((path) => location.pathname.startsWith(path))) {
      return () => {
      };
    }
    const toPrefetch = [...TO_PREFETCH];

    // Start an interval - we will prefetch one file every 200ms
    const interval = setInterval(() => {
      const route = toPrefetch.shift();
      if (!route) {
        clearInterval(interval);
        return;
      }
      // TODO: Fix preloading here - it's not working because the component is wrapped in something that isn't loadable
      route.preload();
    }, 200);

    return () => {
      clearInterval(interval);
    };
  });

};

export function AppRoutes() {
  useBackgroundPrefetch();
  return (
    <Routes>
      {UnguardedRouteMap.map((route) => <Route key={route.path} path={route.path} element={route.element} />)}

      {RouteMap.map((route) => <Route key={route.path} path={route.path} element={route.element} />)}
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}
