import { Button, ButtonProps, Tooltip } from "@mantine/core";
import React, { useState } from "react";

function AsyncButtonInner(props: { onClick: () => Promise<void> } & ButtonProps, ref: React.Ref<HTMLButtonElement>) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const onClick = async () => {
    setLoading(true);
    setError(null);

    try {
      await props.onClick();
    } catch (e: any) {
      setError(e.message);
    } finally {
      setLoading(false);
    }
  };

  return (!error
      ? <Button
        {...props}
        loading={loading}
        onClick={onClick}
        ref={ref}
      />
      : <Tooltip label={error} opened withArrow color="red" position="left" withinPortal multiline maw={300}>
        <Button
          {...props}
          onClick={onClick}
          color="red"
          ref={ref}
        />
        </Tooltip>
  );
}

export const AsyncButton = React.forwardRef(AsyncButtonInner);
