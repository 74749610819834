import { IToken } from "root/client/src/stateMachines/tokens/IToken";

export class PendingToken implements IToken {
  type: "PendingToken" = "PendingToken";

  static isPending(other: any): other is PendingToken {
    return other?.type === "PendingToken";
  }

  equals(other: IToken): boolean {
    return PendingToken.isPending(other);
  }
}

export const PENDING_TOKEN = new PendingToken();
