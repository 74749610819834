import { makeAutoObservable } from "mobx";

import zoomSdk, { Apis, ConfigResponse, RunningContext } from "@zoom/appssdk";

const requiredZoomAppPermissions: Apis[] = [
  "authorize",
  "onAuthorized",
  "expandApp",
  "openUrl",
  "getRunningContext",
  "onShareScreen",
  "getRunningContext",
  "runRenderingContext",
  "drawParticipant",
  "clearParticipant",
  "getMeetingUUID",
  "getMeetingView",
  "onMessage",
  "postMessage",
  "promptShareScreen"
];

const zoomAppPermissionsIfInMeeting: Apis[] = [
  "getMeetingParticipants",
  "getMeetingContext"
];

const FORBID_IN_CLIENT_LOGIN = false; // Just for testing - shouldn't break anything but it's a worse experience
/**
 * A concrete Zoom configuration - this must *not* be shared globally. It is deliberately not a singleton as it's used
 * to ensure that the Zoom app is configured *before* we try to use the Zoom SDK.
 */
export class ZoomConfig {
  public readonly configuration: ConfigResponse;

  public readonly runningContext: RunningContext;

  public readonly meetingUUID: string | null = null; // Only set if we're in a meeting

  constructor(configuration: ConfigResponse, runningContext: RunningContext, meetingUUID: string | null) {
    this.configuration = configuration;
    this.runningContext = runningContext;
    this.meetingUUID = meetingUUID;
  }

  get isInMeeting(): boolean {
    return this.runningContext === "inMeeting" || this.runningContext === "inImmersive";
  }

  get expectedCapabilities(): Apis[] {
    return [...requiredZoomAppPermissions, ...(this.isInMeeting ? zoomAppPermissionsIfInMeeting : [])];
  }

  get missingCapabilities(): Apis[] {
    return this.configuration.unsupportedApis;
  }

  get isInMeetingView(): boolean {
    return this.runningContext === "inMeeting";
  }

  get isImmersiveView(): boolean {
    return this.runningContext === "inImmersive";
  }

  get isMainClientView(): boolean {
    return this.runningContext === "inMainClient";
  }

  get forbidInClientLogin(): boolean {
    return FORBID_IN_CLIENT_LOGIN;
  }

  get isMac(): boolean {
    return this.configuration.userAgent.indexOf("Mac") !== -1;
  }
}
export class ZoomConfigLoader {
  configResponse: ConfigResponse | null = null;

  isInitialised: boolean = false;

  runningContext: RunningContext | null = null;

  private _forceNotZoomApp: boolean = false;

  private _isStarted: boolean = false;

  private _initPromise?: Promise<ZoomConfig | null>;

  constructor() {
    makeAutoObservable(this);
  }

  markInitialised() {
    this.isInitialised = true;
  }

  setConfigResponse(configResponse: ConfigResponse) {
    this.configResponse = configResponse;
  }

  get isZoomApp(): boolean {
    return !this._forceNotZoomApp && navigator.userAgent.indexOf("Zoom") !== -1;
  }

  preventZoomApp() {
    this._forceNotZoomApp = true;
  }

  private async _init(): Promise<ZoomConfig | null> {
    // Try to configure the zoom app
    if (!this.isZoomApp) {
      return null;
    }

    try {
      return await this.configureZoomApi();
    } catch (e: any) {
      if (e.message.indexOf("Zoom Apps SDK is not supported by this browser") !== -1) {
        console.warn("Zoom app detected but not supported by this browser");
        this.preventZoomApp();
        return null;
      }
      throw e;
    }

  }

  private get meetingUUID(): string | null {
    // Get the meeting UUID from the URL query string
    const url = new URL(window.location.href);
    return url.searchParams.get("meetingUUID");
  }

  private async configureZoomApi() {
    let result = await zoomSdk.config({
      capabilities: requiredZoomAppPermissions
    });

    if (result.runningContext === "inMeeting" || result.runningContext === "inImmersive") {
      result = await zoomSdk.config({
        capabilities: [...requiredZoomAppPermissions, ...zoomAppPermissionsIfInMeeting],
      });
    }
    if (result.unsupportedApis.length) {
      console.warn("This appears to be a Zoom app but we are missing the following API permissions to run: ", result.unsupportedApis);
    }

    return new ZoomConfig(result, result.runningContext, this.meetingUUID);
  }

  public async loadZoomConfig() {
    if (!this._isStarted) {
      this._isStarted = true;
      this._initPromise = this._init();
    }
    return this._initPromise || Promise.resolve(null);
  }

  stop() {
    // Nothing to do - But it's here for consistency
  }
}

export const zoomConfig = new ZoomConfigLoader();
