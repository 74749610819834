// TODO 30/04/23 - I had to comment out both onMutate handlers, temporarily disabling the optimistic update. This is because they were causing type errors I don't know how to resolve correctly

import { useMemo } from "react";
import {
    useAccountId, useCanMakeApiRequests, useSelectedProjectId
} from "root/client/src/hooks/useDemoTimeAuth";
import { useModels } from "root/client/src/hooks/useModels";
import { IProject } from "root/interfaces/main";

import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

export interface IProjectsMap extends Record<string, IProject> {
}

export interface UseAllProjectsResult {
  allProjects: (IProject[] | undefined),
  addProject: (project: IProject) => void;
  deleteProject: (project: IProject) => void;
  isLoaded: boolean,
  isLoading: boolean

}

export function useAllProjects() {
  const models = useModels();
  const canMakeFirestoreRequests = useCanMakeApiRequests();
  const accountId = useAccountId();

  const queryClient = useQueryClient();

  const query = useQuery({
    queryKey: [accountId, "projects"],
    queryFn: async () => (await models.projects.all()).reduce((acc, project) => {
      acc[project.id] = project;
      return acc;
    }, {} as IProjectsMap),
    cacheTime: 30 * 60 * 1000,
    staleTime: 15 * 60 * 1000,
    refetchOnMount: false,
    enabled: canMakeFirestoreRequests,
    onSuccess: (data:IProjectsMap) => {
      // Put each project into the query cache under its own key
      for (const project of Object.values(data)) {
        queryClient.setQueryData([accountId, "projects", project.id], project);
      }
    }
  });

  return query;
}

export function useProjectList(): IProject[] {
  const allProjects = useAllProjects();
  return useMemo(() => (allProjects.data ? Object.values(allProjects.data) : []), [allProjects.data]);
}

export function useProjectById(projectId: string | undefined) {
  const models = useModels();
  const allProjects = useAllProjects();
  const canMakeFirestoreRequests = useCanMakeApiRequests();
  const accountId = useAccountId();

  const query = useQuery({
    queryKey: [accountId, "projects", projectId],
    queryFn: async () => {
      if (projectId) {
        return models.projects.get(projectId);
      }
      throw new Error("No project id provided");
    },
    enabled: canMakeFirestoreRequests && !allProjects.isFetching, // Don't fetch if we're already fetching all projects
  });

  return query;
}

function performLocalUpdate(oldElement: IProject, project: Partial<IProject>) {
  return {
    ...oldElement,
    ...project,
  };
}

export function useMutateProject(): { isLoading: boolean; failureReason: object | null; update: (update: (Partial<IProject> & { id: string })) => void; isSuccess: boolean } {
  const models = useModels();
  const canMakeFirestoreRequests = useCanMakeApiRequests();
  const accountId = useAccountId();

  const queryClient = useQueryClient();
  type Context = { previousProjects?: IProjectsMap, previousProject?: IProject; };
  type UpdateData = Partial<IProject> & { id: string };
  const query = useMutation<IProject, object, UpdateData, Context>({
    mutationFn: async (project: UpdateData) => {
      if (!canMakeFirestoreRequests) {
        throw new Error("Cannot make firestore requests");
      }
      await models.projects.update(project.id, project);
      return models.projects.get(project.id);
    },
    onSuccess: async (project:IProject) => {
      await queryClient.cancelQueries([accountId, "projects"], { exact: false });
      const previousProjects = queryClient.getQueryData<IProjectsMap>([accountId, "projects"]);
      queryClient.setQueryData([accountId, "projects", project.id], project);
      queryClient.setQueryData([accountId, "projects"], (old: IProjectsMap | undefined) => {
        if (!old) {
          return old;
        }
        return {
          ...old,
          [project.id]: performLocalUpdate(old[project.id], project),
        };
      });
      return { previousProjects, previousProject: previousProjects?.[project.id] };
    },
    onError: (err, newProject, context) => {
      // Revert back to the old version
      queryClient.setQueryData([accountId, "projects"], context?.previousProjects);
      queryClient.setQueryData([accountId, "projects", newProject.id], context?.previousProject);
    },
    onSettled: () => {
      queryClient.invalidateQueries([accountId, "projects"], { exact: false });
    }
  });

  return useMemo(() => ({
    update: (update: Partial<IProject> & {id: string}) => query.mutate(update),
    isLoading: query.isLoading,
    failureReason: query.failureReason,
    isSuccess: query.isSuccess,
  }), [query.mutate, query.isLoading, query.failureReason, query.isSuccess]);
}

export const useMutateProjectById = (projectId: string) => {
  const mutation = useMutateProject();
  return useMemo(() => ({
    update: (update: Partial<IProject>) => mutation.update({ ...update, id: projectId }),
    isLoading: mutation.isLoading,
    failureReason: mutation.failureReason,
    isSuccess: mutation.isSuccess,
  }), [mutation.update, mutation.isLoading, mutation.failureReason, mutation.isSuccess]);
};

export function useDeleteProject() {
  const models = useModels();
  const canMakeFirestoreRequests = useCanMakeApiRequests();
  const accountId = useAccountId();

  const queryClient = useQueryClient();
  type Context = { previousProjects?: IProjectsMap; };
  type DeleteData = { id: string };
  const query = useMutation<void, object, DeleteData, Context>({
    mutationFn: async (project: DeleteData) => {
      if (!canMakeFirestoreRequests) {
        throw new Error("Cannot make firestore requests");
      }
      await models.projects.delete(project.id);
    },
    // TODO: 30/04/23 - Speak to @karainstantmarketing to find out how to restore this. (It's causing a build error - return type needs to be the same as mutationFn I think)
    // onMutate: async (project:IProject) => {
    //   await queryClient.cancelQueries([accountId, "projects"], { exact: false });
    //   const previousProjects = queryClient.getQueryData<IProjectsMap>([accountId, "projects"]);
    //   queryClient.setQueryData([accountId, "projects"], (old: IProjectsMap | undefined) => {
    //     if (!old) {
    //       return old;
    //     }
    //     const newProjects = { ...old };
    //     delete newProjects[project.id];
    //     return newProjects;
    //   });
    //   return { previousProjects };
    // },
    onError: (err, newProject, context) => {
      // Revert back to the old version
      queryClient.setQueryData([accountId, "projects"], context?.previousProjects);
    },
    onSettled: (_data, _error, deleteData) => {
      queryClient.invalidateQueries([accountId, "projects"], { exact: false });
      queryClient.removeQueries([accountId, "projects", deleteData.id]);
    }
  });

  return useMemo(() => ({
    delete: (projectId: string) => query.mutate({ id: projectId }),
    isLoading: query.isLoading,
    failureReason: query.failureReason,
    isSuccess: query.isSuccess,
  }), [query.mutate, query.isLoading, query.failureReason, query.isSuccess]);
}

export const useSelectedProject = () => {
  const selectedProjectId = useSelectedProjectId().projectId;
  return useProjectById(selectedProjectId || "default");
};

export const useMutateSelectedProject = () => {
  const selectedProjectId = useSelectedProjectId().projectId;

  return useMutateProjectById(selectedProjectId || "default");
};
