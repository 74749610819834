import { IToken } from "root/client/src/stateMachines/tokens/IToken";

export class NoToken implements IToken {
  type: "NoToken" = "NoToken";

  constructor() {
  }

  static matches(token: IToken): token is NoToken {
    return token.type === "NoToken";
  }

  equals(other: IToken): boolean {
    return NoToken.matches(other);
  }
}

export const NO_TOKEN = new NoToken();
