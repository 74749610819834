import { IRemotionAudio } from "interfaces/videoConfig";

/* @allow-client */
export const DIRECT_MODE_UPLOAD_CHUNK_SIZE = 1024 * 1024 * 8; // 8MB
export const SESSION_COOKIE = "__session";
export const REFRESH_COOKIE_EXISTS = "jwt_refresh_exists";

export const DEFAULT_INTRO:IRemotionAudio = {
    createdAtMs: 0,
    url: "https://storage.googleapis.com/demotime_audio/defaultIntro.mp3",
    duration: 2.64,
    script: "This is a summary of YOUR exact demo."
};

export const DEFAULT_OUTRO:IRemotionAudio = {
    createdAtMs: 0,
    duration: 2.31,
    script: "Thank you for your time on this demo",
    url: "https://storage.googleapis.com/demotime_audio/defaultOutro.mp3",
};
