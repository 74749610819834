import {
    collection, deleteDoc, doc, DocumentData, getDoc, getDocs, onSnapshot, query, QueryConstraint,
    setDoc, Unsubscribe, WithFieldValue
} from "firebase/firestore";
import { MutableRefObject, useEffect, useMemo, useRef } from "react";
import { useAccountId, useSelectedProjectId } from "root/client/src/hooks/useDemoTimeAuth";
import { wrapPromiseError } from "root/client/src/libs/notifications";
import { IProject } from "root/interfaces/main";
import {
    IAiField, IEmailTemplate, IMeeting, IMeetingParticipant,
} from "root/interfaces/models";
import { IRecipe } from "root/interfaces/videoConfig";
import { db } from "src/config";
import { IProjectsMap } from "src/hooks/useAllProjects";
import { useApi } from "src/hooks/useApi";

import { useQuery, useQueryClient } from "@tanstack/react-query";
import { IOnboardingSteps } from "root/interfaces/onboarding";

const MAX_CONNECTION_WAIT_SECONDS = 10;

// A custom hook that builds on useLocation to parse the query string for you.

export class Model<Type extends WithFieldValue<DocumentData>> {
  scope: "account"|"project";

  path:string[];

  private debounceTimers:{[id:string]:NodeJS.Timer} = {};

  private accountIdRef: MutableRefObject<string|undefined>;

  private projectIdRef: MutableRefObject<string|undefined>;

  private onMutate: ((id:string, data:Partial<Type>) => void)|undefined = undefined;

  private customUpdateFunction: ((id:string, data:Partial<Type>) => void)|undefined = undefined; // replace update with a custom function. Used for useMutationQuery etc. so cache is invalidated without forcing that API

  constructor(accountIdRef:MutableRefObject<string|undefined>, projectIdRef:MutableRefObject<string| undefined>, scope:"account"|"project", path:string[], options?:IModelOptions<Type>) {
    this.accountIdRef = accountIdRef;
    this.projectIdRef = projectIdRef;
    this.path = path;
    this.scope = scope;
    if (options?.onMutate) {
      this.onMutate = options.onMutate;
    }

    if (options?.customUpdateFunction) {
      this.customUpdateFunction = options.customUpdateFunction;
    }
  }

  get accountId():string {
    return this.accountIdRef.current || "";
  }

  get projectId():string {
    return this.projectIdRef.current || "";
  }

  private basePath(overrideProjectId?:string):string[] {
    if (this.scope === "account") {
      return ["accounts", this.accountId];
    } else {
      return ["accounts", this.accountId, "projects", overrideProjectId || this.projectId];
    }
  }

  async get(id:string, overrideProjectId?:string):Promise<Type> {
    if (!id) {
      throw new Error(`Get called with no id for model ${this.path}`);
    }
    try {
      const path = this.basePath(overrideProjectId).concat(this.path);
      const result = (await getDoc(doc(db, path.join("/"), id))).data() as Type;
      // @ts-ignore
      if (result) result.id = id;
      return result;
    } catch (err) {
      console.error("Error getting from firebase", this.scope, this.path, err);
      throw err;
    }
  }

  getQuery(id:string, overrideProjectId?: string) {
    const path = this.basePath(overrideProjectId).concat(this.path);

    async function loadData() {
      try {
        const result = (await getDoc(doc(db, path.join("/"), id))).data() as Type;
        // @ts-ignore
        if (result) result.id = id;
        return result;
      } catch (err) {
        console.error("Error getting from firebase", path, err);
        throw err;
      }
    }

    return useQuery<Type, Error>({
      queryKey: path,
      queryFn: async () => loadData(),
    });
  }

  async update(id:string, docData:Partial<Type>, overrideProjectId?:string):Promise<void> {

    if (!this.customUpdateFunction) {
      console.log(`update firestore doc ${this.path}/${id}`, docData);
      const path = this.basePath(overrideProjectId).concat(this.path);
      await setDoc(doc(db, path.join("/"), id), docData, { merge: true });
      if (this.onMutate) {
        this.onMutate(id, docData);
      }
    } else {
      this.customUpdateFunction(id, docData);
    }
  }

  async updateDebounced(id:string, docData:Partial<Type>):Promise<void> {
    console.log("updateDebounced", id, docData);
    if (this.debounceTimers[id]) {
      clearTimeout(this.debounceTimers[id]);
    }
    this.debounceTimers[id] = setTimeout(() => {
      wrapPromiseError(this.update(id, docData), "Error updating model");
      delete this.debounceTimers[id];
    }, 400);
  }

  async create(docData:Type, overrideProjectId?:string):Promise<void> {
    // @ts-ignore
    const id:string = String(docData.id);
    if (!id) {
      throw new Error(`Create called with no id for model ${this.path}`);
    }
    const path = this.basePath(overrideProjectId).concat(this.path);
    await setDoc(doc(db, path.join("/"), id), docData);
    if (this.onMutate) {
      this.onMutate(id, docData);
    }
  }

  async delete(id:string, overrideProjectId?:string) {
    const path = this.basePath(overrideProjectId).concat(this.path);
    await deleteDoc(doc(db, path.join("/"), id));
    if (this.onMutate) {
      this.onMutate(id, {});
    }
    return;
  }

  async all(queryConstraints?:QueryConstraint[], overrideProjectId?:string):Promise<Type[]> {
    const path = this.basePath(overrideProjectId).concat(this.path);
    return (await getDocs(
      query(
        collection(db, path.join("/")),
        ...queryConstraints || [],
      )
    )).docs.map((loadedDoc) => ({ ...loadedDoc.data() as Type, id: loadedDoc.id }));
  }

  setProject(project:string) {
    this.projectIdRef.current = project;
  }

  setAccount(account:string) {
    this.accountIdRef.current = account;
  }

  watchAll(onDocCallback:(docs:Type[]) => any, queryConstraints?:QueryConstraint[]):Unsubscribe {
    const path = this.basePath().concat(this.path);
    const q = query(collection(db, path.join("/")), ...queryConstraints || []);
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const docs:Type[] = snapshot.docs.map((loadedDoc) => ({ ...loadedDoc.data() as Type, id: loadedDoc.id }));
      onDocCallback(docs);
    });
    return unsubscribe;
  }

  watchId(id: string, onDocCallback: (doc: Type | null) => void, overrideProjectId?:string): Unsubscribe {
    const path = this.basePath(overrideProjectId).concat(this.path);
    const unsubscribe = onSnapshot(doc(db, path.join("/"), id), (snapshot) => {
      if (snapshot.exists()) {
        const doc2 = { ...snapshot.data() as Type, id: snapshot.id } as Type;
        onDocCallback(doc2);
      } else {
        onDocCallback(null);
      }
    });
    return unsubscribe;
  }
}

export interface ModelTypes {
  recipes: Model<IRecipe>;
  projects: Model<IProject>;
  meetings: Model<IMeeting>;
  meetingParticipants(meetingId: string): Model<IMeetingParticipant>;
  customSettings: Model<any>;
  emailTemplates: Model<IEmailTemplate>;
  aiFields: Model<IAiField>;
  onboarding: Model<IOnboardingSteps>;
}

interface IModelOptions<Type> {
  onMutate?:(id:string, data:Partial<any>|null) => void;
  customUpdateFunction?: (id:string, data:Partial<Type>) => void;
}

export function useModels(): ModelTypes {
  const accountId = useAccountId();

  const projectId = useSelectedProjectId().projectId;

  // Passed into the models. This can be mutated directly so that the models don't have to be re-created
  const accountRef = useRef(accountId);
  const projectRef = useRef(projectId);
  const api = useApi();
  const queryClient = useQueryClient();
  
  async function modelUpdatedCallback() {
    api.post("api", "configUpdated", {});
  }

  const models = useMemo(() => ({
    recipes: new Model<IRecipe>(accountRef, projectRef, "project", ["recipes"], { onMutate: () => modelUpdatedCallback() }),
    meetings: new Model<IMeeting>(accountRef, projectRef, "account", ["meetings"]),
    projects: new Model<IProject>(accountRef, projectRef, "account", ["projects"], { onMutate: async (id, updates) => {
      await queryClient.cancelQueries([accountId, "projects"], { exact: false });
      const previousProjects = queryClient.getQueryData<IProjectsMap>([accountId, "projects"]);
      if (!previousProjects?.[id]) {
        return;
      }
      const project:IProject = { ...previousProjects[id], ...updates };
      queryClient.setQueryData([accountId, "projects", id], project);
      queryClient.setQueryData([accountId, "projects"], (old: IProjectsMap | undefined) => {
        if (!old) {
          return old;
        }
        return {
          ...old,
          [project.id]: { ...old[project.id], ...project },
        };
      });
    } }),
    customSettings: new Model<any>(accountRef, projectRef, "account", ["settings"]),
    meetingParticipants: (meetingId: string) => new Model<IMeetingParticipant>(accountRef, projectRef, "account", ["meetings", meetingId, "participants"]),
    emailTemplates: new Model<IEmailTemplate>(accountRef, projectRef, "project", ["emailTemplates"]),
    aiFields: new Model<IAiField>(accountRef, projectRef, "project", ["aiFields"]),
    onboarding: new Model<IOnboardingSteps>(accountRef, projectRef, "project", ["onboarding"]),
  }), []);

  useEffect(() => {
    accountRef.current = accountId;
    projectRef.current = projectId;
  }, [accountId, projectId]);

  return models;
}

// async function waitForConnection() {
//   let attempts = 0;
//   while(!authFlow.canMakeFirestoreRequests) {
//     await new Promise((resolve) => setTimeout(resolve, 50));
//   }
//   if(attempts > (MAX_CONNECTION_WAIT_SECONDS * 200)) {
//     errorMsg("Failed to connect to database. Please contact support");
//     throw new Error("Failed to connect to Firestore");
//   }
// }
