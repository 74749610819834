import { useMemo } from "react";
import {
  useAccessToken, useCanMakeApiRequests, useUserToken
} from "root/client/src/hooks/useDemoTimeAuth";
import { IUserToken } from "root/interfaces/auth";
import { IMeetingPlatforms } from "root/interfaces/main";
import {
  functionUrlApi, functionUrlAuth, functionUrlCredits, functionUrlDevtools, functionUrlPipeline,
  functionUrlZoom
} from "src/config";
import { errorMsg, wrapPromiseError } from "src/libs/notifications";

type IFunctionPath = "api" | "users" | "pipeline" | "devtools" | IMeetingPlatforms | "credits" | "auth";

export interface IInternalApi {
  authExpired(): void;

  processResult(rawResult: Response): Promise<any>;

  post(set: IFunctionPath, functionName: string, body: Object): Promise<any>;

  put(set: IFunctionPath, functionName: string, body: Object): Promise<any>;

  putDebounced(set: IFunctionPath, functionName: string, body: Object): Promise<void>;

  uploadBlob(set: IFunctionPath, functionName: string, file: Blob, fileName: string): Promise<any>;

  get(set: IFunctionPath, functionName: string): Promise<any>;

  delete(set: IFunctionPath, functionName: string, target?: string): Promise<any>;
}

// A custom hook that builds on useLocation to parse the query string for you.
export class InternalApi implements IInternalApi {
  user: IUserToken;

  authToken: string;

  private debounceTimers: { [id: string]: NodeJS.Timer } = {};

  constructor(user: IUserToken, authToken: string) {
    this.user = user;
    this.authToken = authToken;
  }

  authExpired() {
    console.error("Auth expired");
    errorMsg("Your session has expired. Please log in again.");
  }

  async processResult(rawResult: Response) {
    if (rawResult.status === 401) {
      this.authExpired();
      return {};
    }
    let result: any = {};
    try {
      result = await rawResult.json();
    } catch (err) {
      console.warn("Could not parse JSON from response", rawResult);
      return {};
    }
    if (rawResult.status > 299 && result.error) {
      console.error(result.error);
      errorMsg(result.error);
    }
    return result;
  }

  getFunctionUrl(set: IFunctionPath) {
    switch (set) {
      case "api":
        return functionUrlApi;
      case "pipeline":
        return functionUrlPipeline;
      case "devtools":
        return functionUrlDevtools;
      case "credits":
        return functionUrlCredits;
      case "zoom":
        return functionUrlZoom;
      case "auth":
        return functionUrlAuth;
      default:
        throw new Error(`Unknown function set ${set}`);
    }
  }

  async post(set: IFunctionPath, functionName: string, body: Object) {
    const token = this.authToken;
    if (functionName[0] === "/") {
      throw new Error("postFunction names should not start with a slash");
    }
    const functionUrl = this.getFunctionUrl(set);
    const rawResult = await fetch(`${functionUrl}/${functionName}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json"
      },
      body: JSON.stringify(body)
    });
    return this.processResult(rawResult);
  }

  async put(set: IFunctionPath, functionName: string, body: Object) {
    const token = this.authToken;
    if (functionName[0] === "/") {
      throw new Error("putFunction names should not start with a slash");
    }
    const functionUrl = this.getFunctionUrl(set);
    const rawResult = await fetch(`${functionUrl}/${functionName}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json"
      },
      body: JSON.stringify(body)
    });
    return this.processResult(rawResult);
  }

  async putDebounced(set: IFunctionPath, functionName: string, body: Object): Promise<void> {
    const id = `${set}_${functionName}`;
    if (this.debounceTimers[id]) {
      clearTimeout(this.debounceTimers[id]);
    }

    this.debounceTimers[id] = setTimeout(() => {
      wrapPromiseError(this.put(set, functionName, body), "Error saving");
      delete this.debounceTimers[id];
    }, 100);
  }

  async uploadBlob(set: IFunctionPath, functionName: string, file: Blob, fileName: string) {
    const token = this.authToken;
    if (functionName[0] === "/") {
      throw new Error("postFunction names should not start with a slash");
    }

    const formData = new FormData();
    formData.append("file", file, fileName);

    const functionUrl = this.getFunctionUrl(set);
    const rawResult = await fetch(`${functionUrl}/${functionName}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData
    });
    return this.processResult(rawResult);
  }

  async get(set: IFunctionPath, functionName: string) {
    const token = this.authToken;

    if (functionName[0] === "/") {
      throw new Error("getFunction names should not start with a slash");
    }
    const functionUrl = this.getFunctionUrl(set);
    const rawResult = await fetch(`${functionUrl}/${functionName}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json"
      }
    });
    if (rawResult.status > 299) {
      console.error(`getFunction status for ${set}/${functionName}: ${rawResult.status}`);
    }
    return this.processResult(rawResult);
  }

  async delete(set: IFunctionPath, functionName: string, target?: string) {
    const token = this.authToken;

    if (functionName[0] === "/") {
      throw new Error("deleteFunction names should not start with a slash");
    }
    let body = {};
    if (target) {
      body = { target };
    }
    const functionUrl = this.getFunctionUrl(set);
    const rawResult = await fetch(`${functionUrl}/${functionName}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json"
      },
      body: JSON.stringify(body),
    });
    return this.processResult(rawResult);
  }
}

/**
 * Variant of Internal Api that just throws an error when you try to do anything
 */
class NotLoggedInInternalApi implements IInternalApi {

  authExpired(): void {
  }

  delete(): Promise<any> {
    throw new Error("Not logged in");
  }

  get(): Promise<any> {
    throw new Error("Not logged in");
  }

  post(): Promise<any> {
    throw new Error("Not logged in");
  }

  processResult(): Promise<any> {
    throw new Error("Not logged in");
  }

  put(): Promise<any> {
    throw new Error("Not logged in");
  }

  putDebounced(): Promise<void> {
    throw new Error("Not logged in");
  }

  uploadBlob(): Promise<any> {
    throw new Error("Not logged in");
  }

}

export function useApi(): IInternalApi {
  const startedAt = Date.now();
  const canMakeRequests = useCanMakeApiRequests();
  const userMetadata = useUserToken();
  const userAuth = useAccessToken();

  // if(!account || !project) return;
  return useMemo(() => {
    if (!canMakeRequests) {
      if (Date.now() - startedAt > 1000) {
        console.warn("No user found");
      }
      return new NotLoggedInInternalApi();
    }

    if (!userAuth || !userMetadata) {
      return new NotLoggedInInternalApi();
    }

    return new InternalApi(userMetadata, userAuth.auth as string);
  }, [userAuth, canMakeRequests]);
}
