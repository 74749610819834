// The signup and onboarding flow is designed/documented here: https://www.notion.so/instantmarketing/Signup-and-Onboarding-Flow-851784590e9b4e3d8b2a9b4859ab3aa3?pvs=4

import { signInWithCustomToken, signOut } from "firebase/auth";
import { IJwtPayload } from "interfaces/auth";
import {
    auth as firebaseAuth, functionUrlAuth, functionUrlAuthSubdomain
} from "root/client/src/config";
import { IToken } from "root/client/src/stateMachines/tokens/IToken";
import { NO_TOKEN, NoToken } from "root/client/src/stateMachines/tokens/NoToken";
import { UnparsableToken } from "root/client/src/stateMachines/tokens/UnparsableToken";
import { tryParseJson } from "root/client/src/stateMachines/tryParseJson";
import { decodeJwtWithoutVerifying } from "root/client/src/stateMachines/utils";

const REFRESH_TOKEN_WHEN_EXPIRING_IN_SECONDS = 60;

/**
 * Immutable representation of a token. Pass me around and analyse the token - but don't update me as
 * changes won't be reflected in the auth flow.
 */
export class Token implements IToken {
  type: "Token" = "Token";

  public readonly auth: string;

  public readonly token: IJwtPayload;

  static isPresent(token: IToken): token is Token {
    return token.type === "Token";
  }

  constructor(auth: string, decodedToken: IJwtPayload, private readonly timeInMsCallback: () => number = () => Date.now()) {
    this.auth = auth;
    this.token = decodedToken;

  }

  get needRefreshingAt() {
    return this.token.exp - REFRESH_TOKEN_WHEN_EXPIRING_IN_SECONDS;
  }

  isExpired() {
    return this.token.exp < this.timeInMsCallback() / 1000;
  }

  needsRefresh() {
    return this.needRefreshingAt < this.timeInMsCallback() / 1000;
  }

  static tryParseTokenSafe(auth?: string) {
    if (!auth) {
      return NO_TOKEN;
    }
    const decodedToken = decodeJwtWithoutVerifying(auth);

    try {
      if (!decodedToken) {
        return new UnparsableToken(auth);
      }
      return new Token(auth, decodedToken);
    } catch (e) {
      return new UnparsableToken(auth);
    }
  }

  /**
   * Will try to parse the auth string, but will throw an error if it fails for any reason.
   * @param auth
   */
  static tryParseTokenUnsafe(auth?: string): Token {
    const result = Token.tryParseTokenSafe(auth);
    if (UnparsableToken.isUnparsable(result)) {
      throw new Error(`Failed to parse token ${auth}`);
    }

    if (NoToken.matches(result)) {
      throw new Error(`Token is not present ${auth}`);
    }
    return result;
  }

  /**
   * Token update methods all return a response with a body like {token: "sadascdhaci.asdasd.asdassd"} or {error: "some error"}
   *
   * We can handle this uniformly by parsing the response and throwing an error if the response is not ok or if the response contains an error.
   * @param result
   * @private
   */
  static async readTokenFromResponse(result: Response) {
    const responseBody = await result.text();
    const response = tryParseJson<{ token?: string, error?: string }>(responseBody);
    if (!result.ok || response?.error) {
      throw new Error(`Failed to load JWT: ${response?.error}`);
    }
    if (!response?.token) {
      throw new Error("Failed to load JWT: no token returned");
    }
    return Token.tryParseTokenUnsafe(response.token);
  }

  async refresh(): Promise<Token> {
    const result = await fetch(`${functionUrlAuthSubdomain}/refresh`, {
      method: "POST",
      credentials: "include" // this is required for the cookie to be sent as we have to run the refresh on the subdomain since it's got the jwt_refresh cookie
    });
    return Token.readTokenFromResponse(result);
  }

  async switchProject(projectId: string): Promise<Token> {
    const result = await fetch(`${functionUrlAuth}/project/${projectId}`, { // this endpoint sets the cookie and returns the token for immediate use
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      }
    });
    return Token.readTokenFromResponse(result);
  }

  async imitateUser(userId: string): Promise<Token> {
    if (!this.token?.claims?.super_admin) {
      throw new Error("Not super admin");
    }

    const result = await fetch(`${functionUrlAuthSubdomain}/refresh/imitate-user`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({
        userId
      })
    });

    return Token.readTokenFromResponse(result);
  }

  async stopImitatingUser(): Promise<Token> {
    if (!this.token.m?.ou) {
      throw new Error("Not imitating");
    }

    const result = await fetch(`${functionUrlAuthSubdomain}/refresh/imitate-user`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    });

    return Token.readTokenFromResponse(result);
  }

  async joinAccount(accountId: string, invitationId: string) {
    const result = await fetch(`${functionUrlAuth}/invitations/accept`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        accountId,
        invitationId
      })
    });

    return Token.readTokenFromResponse(result);
  }

  async refreshIfNeeded(): Promise<Token> {
    if (this.needsRefresh()) {
      return this.refresh();
    }
    return this;
  }

  async syncToFirebase(): Promise<void> {
    if (this.isExpired()) {
      throw new Error("Cannot sync expired token to firestore");
    }

    // console.debug(`Syncing token to firebase`, this.token);
    await signInWithCustomToken(firebaseAuth, this.auth);
  }

  /**
   * Will destroy this token - please don't try to use it after this as it will be invalid.
   */
  async destroy(): Promise<{ status: "success" | "failure", errors: Error[] }> {
    const errors: Error[] = [];
    await fetch(`${functionUrlAuth}/logout`, {
      method: "get",
    }).catch((e) => {
      console.error("Failed to send logout message", e);
      errors.push(e);
    });
    await signOut(firebaseAuth).catch((e) => {
      console.error("Failed to sign out of firebase", e);
      errors.push(e);
    });

    return {
      status: errors.length === 0 ? "success" : "failure",
      errors
    };
  }

  equals(other: IToken): boolean {
    if (!Token.isPresent(other)) {
      return false;
    }
    return this.auth === other.auth;
  }
}
