import React from "react";
import { Link } from "react-router-dom";
import * as strings from "src/resources/strings.json";
import {
  TbCalendarEvent, TbDeviceGamepad, TbSettings,
 TbSettingsAutomation } from "react-icons/tb";

import { Group, Text, ThemeIcon, UnstyledButton } from "@mantine/core";
import { AiOutlineAppstoreAdd } from "react-icons/ai";

interface MainLinkProps {
  icon: React.ReactNode;
  color: string;
  label: string;
  href: string;
}

function MainLink({ icon, color, label, href }: MainLinkProps) {

  return (
    <Link to={href}>
      <UnstyledButton
        sx={(theme) => ({
          display: "block",
          width: "100%",
          padding: theme.spacing.xs,
          borderRadius: theme.radius.sm,
          color: theme.colorScheme === "dark" ? theme.colors.dark[0] : theme.black,

          "&:hover": {
            backgroundColor:
              theme.colorScheme === "dark" ? theme.colors.dark[6] : theme.colors.gray[0],
          },
        })}
      >
        <Group>
          <ThemeIcon color={color} variant="light">
            {icon}
          </ThemeIcon>

          <Text size="sm">{label}</Text>
        </Group>
      </UnstyledButton>
    </Link>
  );
}

export function MainLinks() {
  const SHOW_PLAYGROUND = import.meta.env.DT_SHOW_PLAYGROUND;
  const data = [

    // { icon: <TbHome size={16} />, color: "violet", label: "Home", href: "/" },
    { icon: <TbCalendarEvent size={16} />, color: "violet", label: "Meeting Recaps", href: "/meetings" },
    { icon: <AiOutlineAppstoreAdd size={16} />, color: "cyan", label: strings.scenes, href: "/content" },
    { icon: <TbSettingsAutomation size={16} />, color: "red", label: "Automations", href: "/automations" },
    // { icon: <TbBrush size={16} />, color: "blue", label: "Branding", href: "/settings" },
    // { icon: <GoWorkflow size={16} />, color: "red", label: "Use Cases", href: "/usecases" },
    // { icon: <Robot size={16} />, color: "grape", label: strings.aiScenes, href: "/ai_scenes" },
    // { icon: <MailFast size={16} />, color: "cyan", label: strings.followup, href: "/followup" },
    // { icon: <Notebook size={16} />, color: 'cyan', label: 'Stories', href: 'advanced' },
    { icon: <TbSettings size={16} />, color: "gray", label: strings.settings, href: "/settings" }
  ];

  if (SHOW_PLAYGROUND === "true") {
    data.push(
      { icon: <TbDeviceGamepad size={16} />, color: "gray", label: strings.playground, href: "/playground" }
    );
  }
  const links = data.map((link) => <MainLink {...link} key={link.label} />);
  return <div>{links}</div>;
}
