import React, { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { locationObservable } from "root/client/src/stateMachines/LocationObservable";
import { ZoomConfig, zoomConfig } from "root/client/src/stateMachines/zoomConfigLoader";
import { authFlow } from "src/stateMachines/AuthFlow";

export const AuthFlowContext = createContext<typeof authFlow>(undefined as any);
export const ZoomConfigContext = createContext<typeof zoomConfig>(undefined as any);

(async () => {
  // Starts the zoom and auth config literally as soon as possible.
  // This cannot replace the useEffect() hook as it will not be called on navigation or hot reload.
  await zoomConfig.loadZoomConfig();
  await authFlow.start();
})().catch((e) => {
  console.error("Failed to start", e);
});

export function MobxProvider({ children }: { children: React.ReactNode }) {
  useNavigate();

  useEffect(() => {
    (async () => {
      await zoomConfig.loadZoomConfig();
      await authFlow.start();
    })().catch((e) => {
      console.error("Failed to start", e);
    });

    return () => {
      authFlow.stop();
      zoomConfig.stop();

    };
  }, [zoomConfig, authFlow]);

  return (
    <AuthFlowContext.Provider value={authFlow}>
      <ZoomConfigContext.Provider value={zoomConfig}>

        {children}
      </ZoomConfigContext.Provider>
    </AuthFlowContext.Provider>
  );
}

export function useAuthFlow() {
  const flow = useContext(AuthFlowContext);
  if (!flow) {
    throw new Error("No auth flow");
  }
  return flow;
}

export function useZoomConfigLoader() {
  const zoomConfigLoader = useContext(ZoomConfigContext);
  if (!zoomConfigLoader) {
    throw new Error("No zoom config");
  }

  const [data, setData] = useState<ZoomConfig | undefined>(undefined);
  const [error, setError] = useState<Error | undefined>(undefined);
  const [isFetched, setIsFetched] = useState(false);

  useEffect(() => {
    zoomConfigLoader.loadZoomConfig().then((conf) => {
      setData(conf!);
      setError(undefined);
      setIsFetched(true);
    }, (e) => {
      setError(e);
      setIsFetched(true);
    });
  }, [zoomConfigLoader]);
  return useMemo(() => ({
    zoomConfig: data,
    isFetched,
    error,
  }), [data, isFetched, error]);
}

export function useIsPopup() {
  return locationObservable.isPopup;
}
