import { makeAutoObservable } from "mobx";

/**
 * Literally just wraps the current page location (and forcepage) in an observable so it can be used in other mobx classes
 */
export class LocationObservable {
  public location: string = "";

  constructor() {
    makeAutoObservable(this);
  }

  public setLocation(location: string) {
    console.log("Setting location", location);
    this.location = location;
  }

  get isPopup() {
    return sessionStorage.getItem("popup") === "true"
  }
}

export const locationObservable = new LocationObservable();
