import React, { ReactNode } from "react";

import { createStyles } from "@mantine/core";

const useStyles = createStyles(() => ({
  layoutSimple: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  }
}));

export function LayoutSimple({ children }: { children: ReactNode }) {
  const { classes } = useStyles();

  return (
    <div className={classes.layoutSimple}>
      { children }
    </div>
  );
}
