import { TbAlertTriangle, TbCheck, TbX } from "react-icons/tb";

import { showNotification } from "@mantine/notifications";
import React from "react";

export function infoMsg(title:string, message?:string) {
  console.info(title, message);
    showNotification({
      title,
      message,
    });
}

export function warnMsg(title:string, message?:string) {
  console.warn(title, message);
    showNotification({
      title,
      message,
      color: "yellow",
      icon: <TbAlertTriangle size={18} />
    });
}

export function successMsg(title:string, message?:string) {
    console.log("success", title, message);
    showNotification({
      title,
      message,
      color: "teal",
      icon: <TbCheck size={18} />,
    });
}

export function errorMsg(title:string, message?:string) {
    console.error(title, message);
    showNotification({
      title,
      message,
      color: "red",
      icon: <TbX size={18} />
    });
}

export const wrapPromiseError = (promise: Promise<any>, title: string) => {
  promise.catch((e) => {
    console.error(e);
    errorMsg(title, e.message);
  });
};
