import { useMemo } from "react";
import { useAuthFlow } from "root/client/src/stateMachines/mobxContext";
import { IJwtPayload, IUserToken } from "root/interfaces/auth";
import { authFlow as dtAuthFlow } from "src/stateMachines/AuthFlow";

export function useDemoTimeAuth(): typeof dtAuthFlow {
  // Use a reducer to manage the state
  return useAuthFlow();
}

export const useAccessToken = () => ({ auth: useAuthFlow()?.auth, token: useAuthFlow()?.token });

export const useAccountId = () => useAuthFlow()?.token?.claims?.accountId;
export const useIsGuest = () => useAuthFlow()?.token?.claims?.accountId === "GUEST";

export const useIsLoggedIn = () => useAuthFlow().isLoggedIn;

export const useCanMakeApiRequests = () => useAuthFlow().canMakeApiRequests;

export const useCanMakeFirestoreRequests = () => useAuthFlow().canMakeFirestoreRequests;

export const useIsLoadingAuth = () => useAuthFlow().isLoading;

export const useAuthRedirectingTo = () => useAuthFlow().redirectingTo;

export const useIsLoggedInStateKnown = () => useAuthFlow().isLoginStateKnown;

export const useIsImitating = () => !!useAuthFlow().currentUser?.originalUser;

export const useSelectedProjectId = () => {
  const authFlow = useAuthFlow();

  const updateProjectId = useMemo(() => (projectId: string | undefined) => {
    authFlow.updateProjectId(projectId).catch((e) => {
      console.error("Error updating project id", e);
    });
  }, [authFlow]);

  // Has to go in useMemo to prevent constant refreshes (refresh is checked by reference not structure)
  return useMemo(() => ({ projectId: authFlow.selectedProject, setProjectId: updateProjectId }), [authFlow.selectedProject]);

};

export const useCurrentJwt = (): IJwtPayload | undefined => useAuthFlow().token;
export const useUserToken: () => IUserToken | undefined = () => {
  const jwt = useCurrentJwt();

  return useMemo(() => (jwt ? {
    account: jwt.claims.accountId,
    uid: jwt.uid,
    super_admin: jwt.claims.super_admin,
    roles: jwt.claims.roles || {
      read: false,
      write: false,
      present: false,
      admin: false,
      super_admin: false
    },
    originalUser: jwt.m?.ou,
    photoURL: jwt.m?.im,
    email: jwt.m?.em,
    displayName: jwt?.m?.n || "Unknown user"
  } : undefined), [jwt?.jti]);
};

export const useFirstname = () => {
  const metadata = useUserToken();
  return metadata?.displayName?.split(" ")[0] || "User";
};

export const useIsAdmin = () => {
  const claims = useAuthFlow().token?.claims;
  return claims?.roles?.admin || claims?.super_admin;
};

export const useIsSuperAdmin = () => useAuthFlow()?.token?.claims?.super_admin;

export const useRoles = () => useAuthFlow()?.token?.claims?.roles;
