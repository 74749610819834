import { observer } from "mobx-react";
import { useAllProjects, useProjectById } from "root/client/src/hooks/useAllProjects";
import {
  useCanMakeApiRequests, useIsAdmin, useIsLoggedInStateKnown, useSelectedProjectId, useUserToken
} from "root/client/src/hooks/useDemoTimeAuth";
import { useLogout } from "src/hooks/useLogout";
import { TbChevronLeft, TbChevronRight } from "react-icons/tb";
import { usePostHog } from "posthog-js/react";
import {
  Avatar, Badge, Box, Group, Menu, Text, UnstyledButton, useMantineTheme
} from "@mantine/core";
import React, { useEffect } from "react";

export const User = observer(() => {
  const theme = useMantineTheme();
  const readyToShow = useCanMakeApiRequests();

  const loginStateKnown = useIsLoggedInStateKnown();

  const projects = useAllProjects();
  const allProjects = projects.data || {};
  const user = useUserToken();
  const projectSelection = useSelectedProjectId();
  const posthog = usePostHog();

  const selectedProjectResult = useProjectById(projectSelection.projectId || "");

  const selectedProject = selectedProjectResult.data;
  const isAdmin = useIsAdmin();
  const logout = useLogout();

  function setProject(projectId: string) {
    projectSelection.setProjectId(projectId);
  }

  useEffect(() => {
    if (user?.displayName) {
      posthog?.identify(user?.uid, {
        email: user?.email,
        name: user?.displayName,
        project: selectedProject?.name,
      });
    }
  }, [user?.displayName, selectedProject?.name, posthog, user?.email, user?.uid]);

  return (

    <Box
      sx={{
        paddingTop: theme.spacing.sm,
        borderTop: `1px solid ${theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[2]
          }`,
      }}
    >
      <UnstyledButton
        sx={{
          display: "block",
          width: "100%",
          padding: theme.spacing.xs,
          borderRadius: theme.radius.sm,
          color: theme.colorScheme === "dark" ? theme.colors.dark[0] : theme.black,

          "&:hover": {
            backgroundColor:
              theme.colorScheme === "dark" ? theme.colors.dark[6] : theme.colors.gray[0],
          },
        }}
      >

        {(readyToShow && user) ? (
          <Menu>
            <Menu.Target>
              <Group>
                <Avatar
                  src={user?.photoURL}
                  radius="xl"
                />
                <Box sx={{ flex: 1 }}>
                  <Text size="sm" weight={500}>
                    {user.email}
                  </Text>
                  <Text color="dark" size="xs" data-testid="projectName">
                    {selectedProject?.name || ""}
                  </Text>
                  {isAdmin ? <Badge color="violet" variant="filled" size="xs" data-testid="isAdmin">Admin</Badge> : null}
                </Box>

                {theme.dir === "ltr" ? <TbChevronRight size={18} /> : <TbChevronLeft size={18} />}
              </Group>
            </Menu.Target>

            <Menu.Dropdown>

              <Menu.Label>Switch project</Menu.Label>
              {Object.entries(allProjects).map(([projectId, possibleProject]) => (
                <Menu.Item key={projectId} onClick={() => setProject(projectId)}>
                  {possibleProject.name}
                </Menu.Item>
              ))}
              <Menu.Label>User</Menu.Label>
              <Menu.Item onClick={() => logout()}>Sign Out</Menu.Item>

            </Menu.Dropdown>

          </Menu>
        )
          : (
            <Group>
              <Avatar
                src={undefined}
                radius="xl"
              />
              <Box sx={{ flex: 1 }}>
                <Text size="sm" weight={500}>{!loginStateKnown && "Not logged in"}</Text>
              </Box>
            </Group>
          )}
      </UnstyledButton>
    </Box>
  );
});
