import { makeAutoObservable } from "mobx";
import { loadClientPKCETimeoutAndRetry } from "root/client/src/libs/zoomSdk";
import { tryParseJson } from "root/client/src/stateMachines/tryParseJson";

import zoomSdk, { OnAuthorizedEvent } from "@zoom/appssdk";
import { clientUrl, functionUrlAuthSubdomain } from "root/client/src/config";
import { ZoomConfig } from "root/client/src/stateMachines/zoomConfigLoader";

export interface LoginCallbacks {
  onNewAuthString(token: string): void;
}

export class ZoomLogin {
  retries: number = 0;

  constructor(private readonly callbacks: LoginCallbacks, private readonly config: ZoomConfig) {
    makeAutoObservable(this);
    this.onZoomAuthorisationCallback = this.onZoomAuthorisationCallback.bind(this); // We're going to use it in a callback so it needs to be bound
  }

  async startLogin() {
    zoomSdk.removeEventListener("onAuthorized", this.onZoomAuthorisationCallback);
    zoomSdk.addEventListener("onAuthorized", this.onZoomAuthorisationCallback);

    if (this.config.isMac || this.config.forbidInClientLogin) {
      // In-client auth does not work on Mac
      await this.tryTraditionalLogin();
      return;
    }

    const pkce = await loadClientPKCETimeoutAndRetry();
    try {
      const authorizeResult = await zoomSdk.authorize({
        codeChallenge: pkce.codeChallenge,
        state: pkce.state
      });
      console.debug("Zoom login result", authorizeResult);
    } catch (e: any) {
      console.error("Failed to start zoom login", e);
      await this.tryTraditionalLogin();
    }
  }

  onZoomAuthorisationCallback(payload: OnAuthorizedEvent) {
    this.onZoomAuthorisation(payload).catch((e: any) => {
      console.error("Failed to handle zoom authorisation", e);
      this.tryTraditionalLogin().catch((loginErr: any) => {
        console.error("Failed to handle zoom authorisation", loginErr);
      });
    });
  }

  private async onZoomAuthorisation(payload: OnAuthorizedEvent) {
    const result = await fetch(`${functionUrlAuthSubdomain}/zoom/clientCallback`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        code: payload.code,
        state: payload.state
      }),
      credentials: "include"
    });
    const responseBody = await result.text();

    const response = tryParseJson<{ token?: string, error?: string }>(responseBody);
    console.debug("Finish Zoom login response", response);

    if (!response) {
      console.error("Failed to login to Zoom", response);
      await this.tryTraditionalLogin();
      return;
    }

    if (response.error) {
      console.error("Failed to login to Zoom", response.error);
      await this.tryTraditionalLogin();
      return;
    }

    if (!response.token) {
      console.error("Failed to login to Zoom", response);
      await this.tryTraditionalLogin();
      return;
    }
    this.callbacks.onNewAuthString(response.token!);
  }

  private async tryTraditionalLogin() {
    if (window.location.href.indexOf("zoom/browserLoginNotice") !== -1) {
      console.warn("Already on browser login notice page");
      return;
    }
    await zoomSdk.openUrl({
      url: `${functionUrlAuthSubdomain}/zoom/loginFromClient`
    });

    // Redirect to a page that tells the user to look at their browser.
    console.log("Redirecting to browser login notice", `${clientUrl}/zoom/browserLoginNotice`);
    window.location.href = `${clientUrl}/zoom/browserLoginNotice`;

  }

  stop() {
    zoomSdk.removeEventListener("onAuthorized", this.onZoomAuthorisationCallback);
  }
}
