// The signup and onboarding flow is designed/documented here: https://www.notion.so/instantmarketing/Signup-and-Onboarding-Flow-851784590e9b4e3d8b2a9b4859ab3aa3?pvs=4

import { signOut } from "firebase/auth";
// import { H } from "highlight.run";
import { IReactionDisposer, makeAutoObservable, runInAction } from "mobx";
import {
    auth as firebaseAuth, functionUrlAuth, functionUrlAuthSubdomain
} from "root/client/src/config";
import { IAuthFlow } from "root/client/src/stateMachines/IAuthFlow";
import { IStartableService } from "root/client/src/stateMachines/IStartableService";
import { redirectIfNeeded } from "root/client/src/stateMachines/shouldRedirect";
import { IToken } from "root/client/src/stateMachines/tokens/IToken";
import { NO_TOKEN } from "root/client/src/stateMachines/tokens/NoToken";
import { PENDING_TOKEN, PendingToken } from "root/client/src/stateMachines/tokens/PendingToken";
import { Token } from "root/client/src/stateMachines/tokens/Token";
import { UnparsableToken } from "root/client/src/stateMachines/tokens/UnparsableToken";
import { zoomConfig } from "root/client/src/stateMachines/zoomConfigLoader";
import { ZoomLogin } from "root/client/src/stateMachines/zoomLogin";
import { IJwtPayload, IUserToken } from "root/interfaces/auth";
import { REFRESH_COOKIE_EXISTS, SESSION_COOKIE } from "root/libs/consts";

const ALLOWED_LOGIN_PLATFORMS = new Set(["google", "zoom", "azure"]);

function parseCookies(cookieString: string) {
  const cookies = cookieString.split("; ");
  const parsedCookies: { [key: string]: string } = {};
  cookies.forEach((cookie) => {
    const [key, value] = cookie.split("=");
    parsedCookies[key] = value;
  });
  return parsedCookies;
}

async function sleep(ms: number) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

export class AuthFlow implements IStartableService, IAuthFlow {
  private _token: IToken = PENDING_TOKEN;

  isLoading: boolean = true;

  firestoreTokenSynced: boolean = false;

  redirectingTo?: string;

  private refresher?: NodeJS.Timer;

  private stopFirebaseSync?: IReactionDisposer;

  public totalChecks = 0; // track the number of times through the checkSignupStatus loop to avoid infinite loops

  private isStarted = false;

  constructor() {
    this.updateTokenAndRunCallback.bind(this);
    this.tryRefreshIfNeeded = this.tryRefreshIfNeeded.bind(this);

    makeAutoObservable(this);

    firebaseAuth.onAuthStateChanged(async (user) => {
      console.log("Auth state changed", user);
      if (user) {
          try {
              const idTokenResult = await user.getIdTokenResult();
              console.log("Custom claims:", idTokenResult.claims);
  
              // For example, if you set a 'role' claim, you can retrieve it like this:
              // const userRole = idTokenResult.claims.role;
              // console.log("User role:", userRole);
          } catch (error) {
              console.error("Error getting ID token result:", error);
          }
      }
  });
  }

  get token(): IJwtPayload | undefined {
    if (Token.isPresent(this._token)) {
      return this._token.token;
    }
    return undefined;
  }

  get auth(): string | undefined {
    if (Token.isPresent(this._token)) {
      return this._token.auth;
    }
    return undefined;
  }

  get isLoginStateKnown() {
    return !PendingToken.isPending(this._token);
  }

  get hasAccount() {
    return !!this.token?.claims?.accountId;
  }

  get isLoggedIn() {
    return !!this.token;
  }

  get canMakeApiRequests() {
    return !!this.token;
  }

  get canMakeFirestoreRequests() {
    return this.firestoreTokenSynced && !!this.token;
  }

  get selectedProject() {
    return this.token?.m?.p;
  }

  get currentUser(): IUserToken | undefined {
    if (!this.token) {
      return undefined;
    }
    return {
      account: this.token.claims.accountId,
      uid: this.token.uid,
      super_admin: this.token.claims.super_admin,
      roles: this.token.claims.roles || {
        read: false,
        write: false,
        admin: false,
      },
      photoURL: this.token.m?.im,
      email: this.token.m?.em,
      displayName: this.token.m?.n || "Unknown user",
      originalUser: this.token.m?.ou,
    };
  }

  setIsLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  private async syncTokenToFirebase(token: IToken, lastToken: IToken) {
    if (token.equals(lastToken)) {
      return;
    }

    if (!Token.isPresent(this._token)) {
      // Log out ot firebase because we don't have a token and we're not logged in
      await signOut(firebaseAuth).catch((e) => {
        console.error("Failed to delete firebase token", e);
      });
      return;
    }

    if (this._token.needsRefresh()) {
      // Can't sync a token that needs to be refreshed to firebase
      return;
    }

    await this._token.syncToFirebase();

    runInAction(() => {
      this.firestoreTokenSynced = true;
    });
  }

  async updateTokenAndRunCallback(token: IToken) {
    const lastToken = this._token;
    if (!lastToken.equals(token)) {
      runInAction(() => {
        this._token = token;
      });
      await this.onTokenUpdateCallback(token, lastToken);
    }
  }

  async onTokenUpdateCallback(token: IToken, lastToken: IToken) {
    try {
      if (Token.isPresent(token) && token.token.m?.em && process.env.NODE_ENV !== "development" && process.env.NODE_ENV !== "dev") {
        // H.identify(token.token.m.em, {
        //   id: token.token.uid,
        //   account: token.token.claims.accountId,
        // });
      }
      await this.syncTokenToFirebase(token, lastToken);
    } catch (e) {
      // Failed to sync to firebase - force a logout
      console.error("Failed to sync token to firebase", e);
      const oldToken = this._token;

      runInAction(() => { // Set this directly rather than calling updateToken to avoid infinite loops
        this._token = NO_TOKEN;
      });

      // Clear the cookie so we don't get stuck in a loop
      if (Token.isPresent(oldToken)) {
        await oldToken.destroy();
      }
      this._startLogin("Failed to sync token to firebase").catch(() => { /* Ignore - handled in async function */
      });
    }
  }

  /**
   * Attempt to refresh the token if needed - should be run on a refresh interval
   */
  tryRefreshIfNeeded() {
    (async () => {
      try {
        if (!Token.isPresent(this._token)) {
          return;
        }

        const result = await this._token.refreshIfNeeded();
        await this.updateTokenAndRunCallback(result);
      } catch (e) {
        console.error("Failed to run refresh callback - forcing logout now", e);
        await this.updateTokenAndRunCallback(NO_TOKEN);
      }
    })().catch(() => { /* Ignore - handled in async function */
    });

  }

  startWatchers() {
    this.stop();

    this.refresher = setInterval(this.tryRefreshIfNeeded.bind(this), 60000);

  }

  async start() {
    if (this.isStarted) {
      return;
    }
    this.isStarted = true;
    this.startWatchers();

    // Load the JWT
    let authString = await this._loadJwtFromCookie();

    let attempts = 0;
    while (!authString && attempts < 2) {
      await sleep(100);
      authString = await this._loadJwtFromCookie();
      attempts += 1;
    }

    const refreshExists = await this._loadHasRefreshTokenFromCookie();

    if (!authString && !refreshExists) {
      await this.updateTokenAndRunCallback(NO_TOKEN);
      await this._startLogin("No auth string in cookie");
      return;
    } else if (!authString) {
      await this.refreshBeforeTokenAvailable();
      return;
    }

    let token = Token.tryParseTokenSafe(authString);

    if (UnparsableToken.isUnparsable(token)) {
      await this.updateTokenAndRunCallback(NO_TOKEN);
      await this._startLogin(`Unparsable token in cookie ${token.auth}`);
      return;
    }

    if (!Token.isPresent(token)) {
      await this.updateTokenAndRunCallback(NO_TOKEN);
      await this._startLogin("No token in cookie");
      return;
    }

    if (!token.token.claims.accountId) {
      await this.updateTokenAndRunCallback(NO_TOKEN);
      await this._startLogin("No account ID in token");
      return;
    }

    if (token.needsRefresh()) {
      try {
        token = await token.refresh();
      } catch (e) {
        console.error("Failed to refresh JWT", e);
        await this.updateTokenAndRunCallback(NO_TOKEN);
        await this._startLogin("Failed to refresh JWT");
        return;
      }
    }

    await this.updateTokenAndRunCallback(token);

    // token exists - does account?
    await this.checkSignupStatus();
  }

  showLoginPage() {
    redirectIfNeeded("login");
  }

  loginWithPlatform(platform: string, queryParams: Record<string, string> = {}) {

    // Check that the platform is allowed
    if (!ALLOWED_LOGIN_PLATFORMS.has(platform)) {
      throw new Error("Invalid login platform");
    }

    // Redirect to /auth/{platform}/login
    const query = new URLSearchParams(queryParams);

    window.location.href = `${functionUrlAuthSubdomain}/${platform}/login?${query.toString()}`;
  }

  async logout() {
    if (Token.isPresent(this._token)) {
      // Send a message to the server to logout
      const result = await this._token.destroy();
      if (result.status === "failure") {
        console.error("Failed to destroy token", result);
      }
    }

    runInAction(() => {
      this._token = NO_TOKEN;
    });

    localStorage.removeItem("REACT_QUERY_OFFLINE_CACHE");
    await this._startLogin("Logged out");
  }

  async _loadJwtFromCookie(): Promise<string | null> {
    const cookies = parseCookies(document.cookie);
    return cookies[SESSION_COOKIE] || null;
  }

  async _loadHasRefreshTokenFromCookie(): Promise<boolean> {
    const cookies = parseCookies(document.cookie);
    return cookies[REFRESH_COOKIE_EXISTS] === "true";
  }

  async _startLogin(reason: string) {
    console.log("Starting login", reason);
    // Figure out if we are zoom
    if (zoomConfig.isZoomApp) {
      // Show the zoom login screen
      const conf = await zoomConfig.loadZoomConfig();

      if (!conf) {
        throw new Error("No zoom config");
      }

      const zoomLogin = new ZoomLogin({
        onNewAuthString: (auth) => {
          this.updateTokenAndRunCallback(Token.tryParseTokenSafe(auth)).catch(
            (e) => console.error("Failed to update token from zoom login", e)
          );
        },
      }, conf);
      await zoomLogin.startLogin();
    } else {
      // Show the login screen
      this.showLoginPage();
    }
  }

  async updateProjectId(projectId?: string) {
    console.log("authflow updateProjectId", projectId);

    if (!Token.isPresent(this._token)) {
      throw new Error("No token to update project ID");
    }

    if (!projectId) {
      throw new Error("No project ID provided");
    }

    const nextToken = await this._token.switchProject(projectId);
    await this.updateTokenAndRunCallback(nextToken);
  }

  /**
   * Refreshes the JWT. Throws if unsuccessful
   */
  async refreshJwt() {
    if (!Token.isPresent(this._token)) {
      throw new Error("No token to refresh");
    }

    const nextToken = await this._token.refresh();
    await this.updateTokenAndRunCallback(nextToken);
  }

  async superAdminImitateUser(userId: string) {
    if (!Token.isPresent(this._token)) {
      throw new Error("No token to imitate user");
    }
    const nextToken = await this._token.imitateUser(userId);
    await this.updateTokenAndRunCallback(nextToken);
  }

  async superAdminStopImitatingUser() {
    if (!Token.isPresent(this._token)) {
      throw new Error("No token to stop imitating user");
    }
    const nextToken = await this._token.stopImitatingUser();
    await this.updateTokenAndRunCallback(nextToken);
  }

  get isGuest() {
    return this.token?.claims.accountId === "GUEST";
  }

  private async checkSignupStatus() {
    if (this.totalChecks < 8) {
      this.totalChecks += 1;
    } else {
      throw new Error("checkSignupStatus is caught in a potential infinite loop");
    }
    if (!this.token) {
      throw new Error("No token");
    }

    const urlParams = new URLSearchParams(window.location.search);

    const invitationCode = urlParams.get("invitation_code") || undefined;
    if (invitationCode) {
      redirectIfNeeded("signup", `/join_account/${this.token?.claims.accountId}?invitationCode=${invitationCode}`);
      return;
    }

    if (!this.token.claims.accountId || this.token.claims.accountId === "GUEST") {
      console.warn(`Forcing signup because account ID is ${this.token.claims.accountId}`);
      if (zoomConfig.isZoomApp) {
        redirectIfNeeded("signup", "/zoom/signup/client");
      } else {
        redirectIfNeeded("signup");
      }
      return;
    }

    if (!this.token.m?.p) { // Project is not ready - validate it with the server
      const result = await fetch(`${functionUrlAuth}/validate/projects`, {
        method: "POST"
      });
      if (result.ok) {
        const resultJson = await result.json();
        const token = Token.tryParseTokenSafe(resultJson.token);

        if (UnparsableToken.isUnparsable(token)) {
          throw new Error("Failed to parse token");
        }

        if (!Token.isPresent(this._token)) {
          throw new Error("No token");
        }

        await this.updateTokenAndRunCallback(token);
      }

      await this.checkSignupStatus();
      return;
    }
  }

  /**
   * Should be called when the user is ready to join a pending invitation.
   */
  async joinAccount(accountId: string, invitationId: string): Promise<void> {
    console.debug(`Trying to accept id: ${invitationId}`);

    if (!Token.isPresent(this._token)) {
      throw new Error("No token to join account");
    }
    const nextToken = await this._token.joinAccount(accountId, invitationId);

    await this.updateTokenAndRunCallback(nextToken);

    await this.checkSignupStatus();
  }

  async createAccount(accountName: string, logoUrl:string|null, domain:string|null) {
    console.log("Creating account...");
    if (!Token.isPresent(this._token)) {
      throw new Error("No token to create account");
    }
    const result = await fetch(`${functionUrlAuth}/account/create`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        accountName,
        logoUrl,
        domain
      })
    });

    const nextToken = await Token.readTokenFromResponse(result);
    await this.updateTokenAndRunCallback(nextToken);
    await this.checkSignupStatus();
    window.document.location.reload();
  }

  stop() {
    if (this.refresher) {
      clearInterval(this.refresher);
    }
    if (this.stopFirebaseSync) {
      this.stopFirebaseSync();
    }
  }

  toJSON() {
    return {
      auth: this.auth,
      token: this.token,
      isLoggedIn: this.isLoggedIn,
      isGuest: this.isGuest,
      totalChecks: this.totalChecks
    };
  }

  /**
   * Will attempt to use a refresh token now before login is available.
   * @private
   */
  private async refreshBeforeTokenAvailable() {
    try {

      const result = await fetch(`${functionUrlAuthSubdomain}/refresh`, {
        method: "POST",
        credentials: "include" // this is required for the cookie to be sent as we have to run the refresh on the subdomain since it's got the jwt_refresh cookie
      });

      let token: IToken = PENDING_TOKEN;
      token = await Token.readTokenFromResponse(result);
      if (Token.isPresent(token)) await this.updateTokenAndRunCallback(token);
    } catch (e) {
      // Start a login
      await this.updateTokenAndRunCallback(NO_TOKEN);
      return this._startLogin("Failed to refresh token");
    }
  }

}

export const authFlow = new AuthFlow();
