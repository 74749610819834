import React, { ReactNode } from "react";
import { Logo } from "root/client/src/components/logo";
import { ImitationReminder } from "root/client/src/components/superadmin/imitationReminder";
import { useAccountId, useIsLoggedInStateKnown } from "root/client/src/hooks/useDemoTimeAuth";

import { AppShell, Center, Group, Header, Navbar, useMantineColorScheme } from "@mantine/core";

import { MainLinks } from "./components/mainLinks";
import { User } from "./components/user";

export function Layout({ children }: { children: ReactNode }) {
    const ready = useIsLoggedInStateKnown();
    const { colorScheme } = useMantineColorScheme();
    const accountId = useAccountId();

    // on mobile, redirect to mobileUnsupportedNotice
    if (window.screen.width < 800 && !window.location.href.includes("mobile_unsupported_notice") && !window.location.href.includes("/popup") && !window.location.href.includes("/recap/") && window.location.pathname !== "/login") {
        window.location.href = "/mobile_unsupported_notice";
    }

    if (!ready) {
      return null;
    }

    return <AppShell
      padding={0}
      navbar={
        <Navbar width={{ base: 300 }} p="xs">
          <Navbar.Section grow mt="xs">
            { accountId && <MainLinks /> }
            {/* <OnboardingProgress /> */}
          </Navbar.Section>
          <Navbar.Section>
            { accountId && <User /> }
          </Navbar.Section>
        </Navbar>
      }
      header={
        <Header height={60}>
          <Group sx={{ height: "100%" }} px={20} position="apart">
            <div className="center-if-small">
              <Group>
                <a href="/"><Logo /></a>
              </Group>
            </div>

          </Group>
        </Header>
      }
      styles={(theme) => ({
        main: {
          backgroundColor:
            theme.colorScheme === "dark" ? theme.colors.dark[8] : theme.white,
        },
      })}
    >

      <Center className={`main-layout ${colorScheme === "dark" ? "theme-dark" : "theme-light"}`}>
        {children}
        <ImitationReminder />

      </Center>

      <br />

           </AppShell>;
}
