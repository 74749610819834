import { IJwtPayload } from "root/interfaces/auth";

export function decodeJwtWithoutVerifying(jwt: string): IJwtPayload {
  if (!jwt) {
    throw new Error("No JWT to decode");
  }
  const [_header, payload, _signature] = jwt.split(".");

  return JSON.parse(atob(payload));
}
