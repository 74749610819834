import React from "react";

import LoadingGif from "../loading.gif";

export default function Loading(props: {delay?: number}) {
  const [render, setRender] = React.useState(props.delay === undefined || props.delay === 0);

  // Allow a delay to be passed in so we don't immediately show a loading screen on a very short load.
  React.useEffect(() => {
    if (props.delay && props.delay > 0) {
      // Create a timeout to render the loading screen after the delay
      const timeout = setTimeout(() => {
        setRender(true);
      }, props.delay);

      return () => {
        clearTimeout(timeout);
      };
    }

    return () => {};
  }, []);

  if (!render) {
    return null;
  }

  return (
    <div style={{ width: "100%" }}>
      <img src={LoadingGif} style={{ opacity: 0.8, width: 120, marginTop: 40, marginBottom: 40, height: 120, display: "block", marginLeft: "auto", marginRight: "auto" }} />
    </div>
  );
}
