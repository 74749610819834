import { functionUrlClientlogs } from "src/config";

import { OTLPTraceExporter } from "@opentelemetry/exporter-trace-otlp-http";
import { OTLPExporterNodeConfigBase } from "@opentelemetry/otlp-exporter-base";
import { Resource } from "@opentelemetry/resources";
import { BatchSpanProcessor } from "@opentelemetry/sdk-trace-base";
import { WebTracerProvider } from "@opentelemetry/sdk-trace-web";
import { SemanticResourceAttributes } from "@opentelemetry/semantic-conventions";

const collectorOptions: OTLPExporterNodeConfigBase = {
  url: `${functionUrlClientlogs}/v1/traces`,
  headers: {},
};
export const traceExporter = new OTLPTraceExporter(collectorOptions);

const provider = new WebTracerProvider({
  resource: new Resource({
    [SemanticResourceAttributes.SERVICE_NAME]: "client",
  })
});

provider.addSpanProcessor(new BatchSpanProcessor(traceExporter, {
  // The maximum queue size. After the size is reached spans are dropped.
  maxQueueSize: 1000,
  // The interval between two consecutive exports
  scheduledDelayMillis: 1000,
}));

provider.register();
